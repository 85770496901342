<template>
  <div class="commodity-list">
    <div class="header">
      <div class="search">
        <van-dropdown-menu>
          <van-dropdown-item class="menu-item"
                             v-model="searchType"
                             :options="searchTypeOptions" />
        </van-dropdown-menu>
        <van-search class="search-content"
                    v-model.trim="searchContent"
                    @search="onSearch"
                    placeholder="请输入搜索关键词" />
        <div class="search-btn"
             @click="onSearch">搜索</div>
      </div>
      <div class="filter">
        <div class="filter-item"
             @click="commodityTypeClick">
          <span>{{ commodityTypeComputed }}</span>
          <van-icon name="arrow-down" />
        </div>
      </div>
      <van-popup v-model="showCommodityType"
                 round
                 position="bottom">
        <van-radio-group v-model="filter.commodityType">
          <custom-popup title="物品类别"
                        :columns="commodityTypeColumns"
                        swipe-duration="500"
                        value-key="name"
                        :select-icon="false"
                        @cancel="showCommodityType = false"
                        @confirm="radioChange">
            <template #option="{option}">
              <div class="commodity-type">
                <van-radio :name="option.id"
                           v-if="option.name">{{ option.name }}</van-radio>
              </div>
            </template>
          </custom-popup>
        </van-radio-group>
      </van-popup>
    </div>
    <div class="main">
      <div class="list">
        <van-list :immediate-check="false"
                  v-model="listStatus.loading"
                  :finished="listStatus.finished"
                  @load="onLoad">
          <div class="list-item"
               v-for="item in list"
               :key="item.id"
               @click="itemClick(item.id)">
            <item-card class="list-item"
                       :title="item.name"
                       :status="parseStatus(item.status)">
              <div class="content">
                <div class="content-row">
                  <div class="content-item"
                       style="width:100%;">
                    <span class="name">物品编码:</span>
                    <span class="value">{{ item.code }}</span>
                  </div>

                </div>
                <div class="content-row">
                  <div class="content-item"
                       style="width:100%;">
                    <span class="name">规格型号:</span>
                    <span class="value">{{ item.specifications }}</span>
                  </div>
                </div>
                <div class="content-row">
                  <div class="content-item">
                    <span class="name">计量单位:</span>
                    <span class="value">{{ item.measuringUnit }}</span>
                  </div>
                  <div class="content-item">
                    <span class="name">物品价格:</span>
                    <span class="value">{{ item.unitPrice }}</span>
                  </div>
                </div>
                <div class="content-row">
                  <div class="content-item"
                       style="width:100%;">
                    <span class="name">生成厂商:</span>
                    <span class="value">{{ item.manufacturer }}</span>
                  </div>
                </div>
                <div class="content-row">
                  <div class="content-item"
                       style="width:100%;">
                    <span class="name">物品条码:</span>
                    <span class="value">{{ item.barcode }}</span>
                  </div>
                </div>
                <div class="content-row">
                  <div class="content-item">
                    <span class="name">备注:</span>
                    <span class="value">{{ item.remark }}</span>
                  </div>
                </div>
              </div>
            </item-card>
          </div>
          <template #finished>
            <div v-if="list.length">没有更多了</div>
          </template>
        </van-list>
      </div>
      <van-empty description="暂无数据"
                 v-if="!list.length" />
    </div>
    <div class="footer">
      <van-button class="add-btn"
                  type="info"
                  round
                  @click="addCommodity">新增物品</van-button>
    </div>
  </div>
</template>

<script>
import "@/assets/less/views/commodity/commodityList.less";
import Filter from "ldap-filters";
import { SUCCESS_CODE } from "@/apis/notification";
import { flatMap, find } from "lodash-es";
export default {
  name: "CommodityList",
  data() {
    return {
      searchType: "name",
      searchContent: "",
      searchTypeOptions: [
        { text: "物品名称", value: "name" },
        { text: "物品条码", value: "barcode" }
      ],

      filter: {
        commodityType: ""
      },
      showCommodityType: false,
      commodityTypeColumns: [],

      listStatus: {
        loading: true,
        finished: false
      },

      list: [],
      page: {
        pageNumber: 0,
        pageSize: 1
      }
    };
  },
  computed: {
    commodityTypeComputed() {
      if (!this.commodityTypeColumns.length) return "物品类别";
      let item = find(this.flatCommodityType, item => item.id == this.filter.commodityType);
      return item ? item.name : "物品类别";
    }
  },
  methods: {
    async getStockItemType() {
      let ret = await this.$api.getStockItemType();
      if (ret.code != SUCCESS_CODE) return;
      let data = ret.data;
      data.forEach(item => {
        if (item.stockItemCategorys.length) {
          item.children = item.stockItemCategorys;
        } else {
          item.children = [{ name: "", id: "" }];
        }
      });
      this.commodityTypeColumns = [{ id: "", name: "所有类别", children: [{ name: "" }] }, ...data];
      this.flatCommodityType = flatMap(data, item => {
        return [item, ...item.stockItemCategorys];
      });
    },
    async getCommodityList(sign = false) {
      let params = {
        pageNumber: this.page.pageNumber,
        pageSize: this.page.pageSize,
        categoryId: this.filter.commodityType ? this.filter.commodityType : null,
        [this.searchType]: this.searchContent ? this.searchContent : null
      };

      // params.query = this.getQuery();
      let ret = await this.$api.getStockItemList({ params });
      if (ret.code != SUCCESS_CODE) return;
      if (sign) {
        this.list = this.list.concat(ret.data);
      } else {
        this.list = ret.data;
      }
      this.listStatus.loading = false;
      this.handlePagination(ret.totalPages);
    },
    handlePagination(totalPages) {
      if (this.page.pageNumber + 1 < totalPages) {
        // 有更多数据
        this.page.pageNumber += 1;
      } else {
        this.listStatus.finished = true;
      }
    },
    getQuery() {
      let filters = [];
      if (this.filter.commodityType.id != "") {
        let query = Filter.attribute("stockItemCategoryId").equalTo(this.filter.commodityType.id);
        filters.push(query);
      }
      if (this.searchContent != "") {
        let query = Filter.attribute(this.searchType).contains(this.searchContent);
        filters.push(query);
      }
      if (!filters.length) {
        let query = Filter.attribute("status").approx("DELETED");
        filters.push(query);
      }
      if (filters.length == 1) {
        return filters[0].toString();
      } else if (filters.length > 1) {
        return Filter.AND(filters).toString();
      }
    },

    commodityTypeClick() {
      this.showCommodityType = true;
    },
    radioChange() {
      this.getInitialData();
      this.showCommodityType = false;
    },
    onSearch() {
      this.getInitialData();
    },
    getInitialData() {
      window.scroll({ top: 0, behavior: "instant" });
      this.listStatus.loading = true;
      this.listStatus.finished = false;
      this.page.pageNumber = 0;
      this.getCommodityList();
    },
    onLoad() {
      this.getCommodityList(true);
    },
    itemClick(id) {
      this.$router.push({
        name: "commodityDetail",
        params: {
          id
        }
      });
    },
    addCommodity() {
      this.$router.push({
        name: "addCommodity"
      });
    },
    parseStatus(arg) {
      if (arg == "ENABLE") {
        return "启用";
      }
      if (arg == "DISABLE") {
        return "禁用";
      }
      if (arg == "DELETED") {
        return "已删除";
      }
    },
    init() {
      this.getStockItemType();
      this.getInitialData();
    }
  },
  created() {
    this.init();
  }
};
</script>

<style></style>
